<template>
  <div class="baiduMap">
    <baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" ak="eYf9sA6yVTFHlh9ytU4a0EYY">
      <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
        <bm-label content="河南省郑州市惠济区水映唐庄49-2" :labelStyle="{ color: 'red', fontSize: '24px' }" :offset="{ width: -185, height: 30 }" />
      </bm-marker>
    </baidu-map>
  </div>
</template>
<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import bmMarker from 'vue-baidu-map/components/overlays/Marker.vue';
import bmLabel from 'vue-baidu-map/components/overlays/Label.vue';
export default {
  name: 'maps',
  data() {
    return {
      center: { lng: 113.669747, lat: 34.879216 },
      zoom: 15
    };
  },
  components: {
    BaiduMap,
    bmMarker,
    bmLabel
  }
};
</script>
<style lang="scss">
.bm-view {
  width: 750px;
  height: 100%;
  .anchorBL,
  .BMap_noprint {
    display: none;
  }
}</style
>>
