<template>
  <div class="contactUs">
    <TopTitle title="联系我们"></TopTitle>
    <div class="maps fac">
      <baidu-map></baidu-map>
      <ul class="ul">
        <li>创作者入驻请联系:baiwanqilin@163.com</li>
        <li>问题反馈邮箱：baiwanqilin1@163.com</li>
        <li>公司电话：13598077718</li>
        <li>合作电话：13598077718</li>
        <li>公司地址：河南省郑州市惠济区水映唐庄49-2</li>
        <li>QQ：344160598</li>
        <li>微信：13598077718</li>
        <li>微博：111546464</li>
      </ul>
    </div>
  </div>
</template>

<script>
import TopTitle from '../components/public/TopTitle';
import BaiduMap from '../components/public/Map.vue';

export default {
  name: 'contactUs',
  components: { TopTitle, BaiduMap }
};
</script>
<style scoped lang="scss">
.contactUs {
  padding-top: 70px;
  .baiduMap,
  .bm-view {
    height: 100%;
  }
  .maps {
    width: 1500px;
    height: 662px;
    margin: 63px auto 80px;
    background-color: #eef2f5;
    .ul {
      padding: 0 100px;
      li {
        color: #000000;
        font-size: 24px;
        padding-bottom: 24px;
      }
    }
  }
}
</style>
